import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-card-head',
    templateUrl: 'card-head.component.html',
    styleUrls: ['card-head.component.scss'],
})
export class CardHeadComponent {
    @Input() sticker: boolean = false;
    @Input() index?: number;
}
