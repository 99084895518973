import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-card-footer',
    templateUrl: 'card-footer.component.html',
    styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
    @Input() marginTop: boolean = false;

    @HostBinding() class = 'card-footer__container';
}
