import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardFooterComponent } from '@app/content/shared/card/card-footer.component';
import { CardContentComponent } from './card-content.component';
import { CardHeadComponent } from './card-head.component';
import { CardRackComponent } from './card-rack.component';
import { CardComponent } from './card.component';

const COMPONENTS = [
    CardComponent,
    CardHeadComponent,
    CardContentComponent,
    CardFooterComponent,
    CardRackComponent,
];

@NgModule({
    imports: [CommonModule],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class CardModule {}
