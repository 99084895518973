import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { CardContentComponent } from '@app/content/shared/card/card-content.component';
import { CardFooterComponent } from '@app/content/shared/card/card-footer.component';

@Component({
    selector: 'ins-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
})
export class CardComponent implements AfterContentInit {
    @ContentChild(CardFooterComponent, { static: false }) footer !: CardFooterComponent;
    @ContentChild(CardContentComponent, { static: false }) content !: CardContentComponent;

    @Input() borders: boolean = false;
    @Input() padding?: 'small' | 'right' | 'left' | 'none';
    @Input() eZine: boolean = false;
    @Input() highlight: boolean = false;
    @Input() borderPrimary: boolean = false;
    @Input() verticalAlign: boolean = false;
    @Input() fullHeight: boolean = false;
    @Input() borderHalf: boolean = false;
    @Input() textCenter: boolean = false;
    @Input() white: boolean = false;
    @Input() flex: boolean = false;

    ngAfterContentInit(): void {
        if (!!this.footer && this.flex) {
            this.content.flex = true;
        }
    }
}
