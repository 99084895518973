import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-card-content',
    templateUrl: 'card-content.component.html',
    styleUrls: ['card-content.component.scss'],
})
export class CardContentComponent {
    @Input() flex: boolean = false;

    @HostBinding('class') get classes(): string {
        return this.flex ? 'card-content__flex-container' : '';
    }

}
