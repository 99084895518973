<div
    class="card"
    [class.card--border-half]="borderHalf"
    [class.card--border-primary]="borderPrimary"
    [class.card--border]="borders"
    [class.card--ezine]="eZine"
    [class.card--flex]="flex"
    [class.card--full-height]="fullHeight"
    [class.card--highlight]="highlight"
    [class.card--text-center]="textCenter"
    [class.card--vertical-align]="verticalAlign"
    [class.card--white]="white"
    [ngClass]="!!padding ? 'card--padding-' + padding : null"
>
    <ng-content></ng-content>
</div>
